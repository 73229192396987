import type { ImageProps } from 'next/image'
import { NetReviewsWidget } from '@marketing-sites/ui/components/NetReviewsWidget'
import Image from 'next/image'
import type { FragmentType } from '@/gql'
import { graphql, useFragment } from '@/gql'

declare global {
  interface Window {
    UC_UI?: {
      showSecondLayer: () => void
    }
  }
}

export const FooterBlock_Fragment = graphql(/* GraphQL */ `
  fragment FooterBlock_Fragment on ComposeComponentWlFooterBlock {
    about3PLearning
    aboutUs
    blakeELearningLogo {
      url
    }
    collaborateWithUs
    contactUs
    dataBreachResponsePlan
    facebook
    instagram
    linkedIn
    pLearningLogo {
      url
    }
    pinterest
    privacyPolicy
    refundPolicy
    responsibleDisclosureProgram
    termsOfUse
    tikTok
    twitter
    youtube
  }
`)

export const WritingLegendsFooterComponent_Query = graphql(/* GraphQL */ `
  query WritingLegendsFooterBlock($id: String!, $locale: String) {
    composeComponentWlFooterBlock(id: $id, locale: $locale) {
      ...FooterBlock_Fragment
    }
  }
`)

export type FooterQuery = FragmentType<typeof FooterBlock_Fragment>

export interface FooterBlockProps {
  query: FooterQuery
}

interface SocialLinkProps {
  href: string
  alt: string
  imageSrc: ImageProps['src']
}

function SocialLink({ href, alt, imageSrc }: SocialLinkProps) {
  return (
    <a
      href={href}
      target="_blank"
      className="relative inline-block h-[30px] w-[30px]"
    >
      <Image alt={alt} src={imageSrc} height={30} width={30} />
    </a>
  )
}

function FacebookLink({ href }: { href: string }) {
  return (
    <SocialLink
      href={href}
      imageSrc="/images/social_icons/facebook@2x.png"
      alt="Facebook Page"
    />
  )
}

function InstagramLink({ href }: { href: string }) {
  return (
    <SocialLink
      href={href}
      imageSrc="/images/social_icons/instagram@2x.png"
      alt="Instagram Profile"
    />
  )
}

function YoutubeLink({ href }: { href: string }) {
  return (
    <SocialLink
      href={href}
      imageSrc="/images/social_icons/youtube@2x.png"
      alt="Youtube Channel"
    />
  )
}

function LinkedinLink({ href }: { href: string }) {
  return (
    <SocialLink
      href={href}
      imageSrc="/images/social_icons/linkedin@2x.png"
      alt="LinkedIn Profile"
    />
  )
}

function PinterestLink({ href }: { href: string }) {
  return (
    <SocialLink
      href={href}
      imageSrc="/images/social_icons/pinterest@2x.png"
      alt="Pinterest Profile"
    />
  )
}

function TwitterLink({ href }: { href: string }) {
  return (
    <SocialLink
      href={href}
      imageSrc="/images/social_icons/twitter@2x.png"
      alt="Twitter Feed"
    />
  )
}

function TikTokLink({ href }: { href: string }) {
  return (
    <SocialLink
      href={href}
      imageSrc="/images/social_icons/tiktok@2x.png"
      alt="TikTok Profile"
    />
  )
}

export function Footer(props: FooterBlockProps) {
  const data = useFragment(FooterBlock_Fragment, props.query)

  const footerLinks = [
    {
      text: 'About Us',
      href: `/about-us`,
    },
    {
      text: 'About 3P Learning',
      href: data.about3PLearning ?? '',
    },
    {
      text: 'Terms of Use',
      href: `/terms`,
    },
    {
      text: 'Privacy Policy',
      href: `/privacy`,
    },
    {
      text: 'Refund Policy',
      href: data.refundPolicy ?? '',
    },
    {
      text: 'Technical Requirements',
      href: `/technical-requirements`,
    },
    {
      text: 'Contact Us',
      href: `/contact-us`,
    },
    {
      text: 'Responsible Disclosure Program',
      href: data.responsibleDisclosureProgram ?? '',
    },
    {
      text: 'Data Breach Response Plan',
      href: data.dataBreachResponsePlan ?? '',
    },
  ]

  return (
    <div className="not-prose mt-auto w-full bg-wl-blue-regatta-700 px-6 py-6">
      <div className="content-container flex-col items-center text-sm md:flex-row md:space-x-8">
        <div className="flex flex-col items-center space-y-4">
          <div className="w-max">
            <Image
              src={data.blakeELearningLogo?.url ?? ''}
              height={64}
              width={64}
              alt="Blake eLearning Logo"
            />
          </div>
          <div className="w-max">
            <Image
              src="/images/3plearning.svg"
              height={64}
              width={100}
              alt="3P Learning Logo"
            />
          </div>
          <div>
            <span className="text-white">
              &copy; {new Date().getFullYear()}
            </span>
          </div>
        </div>
        <div>
          <nav role="navigation" aria-label="Legal and General Information">
            <ul className="flex h-fit flex-wrap justify-center space-x-4 py-4 lg:py-0">
              {footerLinks.map((link) => (
                <li key={link.text} className="mb-2">
                  <a className="text-white hover:underline" href={link.href}>
                    {link.text}
                  </a>
                </li>
              ))}
              <li className="mb-2">
                <button
                  className="text-white hover:underline"
                  onClick={() => {
                    try {
                      window.UC_UI?.showSecondLayer()
                    } catch (error) {
                      alert('An error occurred, please try again shortly.')
                    }
                  }}
                >
                  Your Privacy Choices
                </button>
              </li>
            </ul>
          </nav>
        </div>
        <div>
          <div>
            <div className="mb-4 flex justify-center">
              <NetReviewsWidget />
            </div>
          </div>
          <div>
            <nav role="navigation" aria-label="Social Network">
              <ul className="flex space-x-2">
                <li>
                  <InstagramLink href={data.instagram ?? ''} />
                </li>
                <li>
                  <FacebookLink href={data.facebook ?? ''} />
                </li>
                <li>
                  <YoutubeLink href={data.youtube ?? ''} />
                </li>
                <li>
                  <TwitterLink href={data.twitter ?? ''} />
                </li>
                <li>
                  <PinterestLink href={data.pinterest ?? ''} />
                </li>
                <li>
                  <LinkedinLink href={data.linkedIn ?? ''} />
                </li>
                <li>
                  <TikTokLink href={data.tikTok ?? ''} />
                </li>
              </ul>
            </nav>
          </div>
          <div className="my-4 hidden justify-center">
            <a
              href="https://www.kidsafeseal.com/certifiedproducts/Readingeggs_websites.html"
              target="_blank"
              rel="noreferrer"
            >
              <Image
                alt="Reading Eggs Websites are certified by the kidSAFE Seal Program."
                src="/images/kidsafe-logo.png"
                className="h-auto max-w-full"
                width={100}
                height={100}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
